
import { retrieveButter,
         getDictionary,
         getLang } from '~/utils/butterUtils'

export default {
  head () {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageMetaDescription
        }
      ],
      link: [ 
        {
          rel: 'canonical',
          href: process.env.url+this.$route.path
        }
      ]
    }
  },
  async asyncData ({ store }) {
    await retrieveButter(store, 'pages/faqs/_slug/index.vue asyncData ')
  },
  data () {
    return {
      isLoading: true
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    lang () {
      return getLang(this.$i18n.locale)
    },
    pageMetaDescription () {
      // remove the number from the question
      const spaceIndex = this.page.question.indexOf(" ")
      const question = this.page.question.substring(spaceIndex).trim()
      
      return question
    },
    pageTitle () {
      // remove the number from the question
      const spaceIndex = this.page.question.indexOf(" ")
      const question = this.page.question.substring(spaceIndex).trim()

      return process.env.siteTitle + " | " +  question
    },
    oneFAQ () {
      let b0 = this.butterFaqQuestions
      if (b0) {
        return b0.find(({slug}) => slug.toLowerCase() === this.$route.params.slug.toLowerCase())
      } else {
        return {
          "question_enus" : "",
          "answer_enus" : "Retrieving the FAQ from our database.",
          "question_eses" : "",
          "answer_eses" : "Retrieving the FAQ from our database."
        }
      }
    },
    page () {
      const QA = this.oneFAQ
      const FAQ = {}
      FAQ.question = String(QA['question_' + this.lang])
      FAQ.body = QA['answer_' + this.lang]
      return FAQ
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    butterFaqQuestions () {
      return this.$store.state.butterFaqQuestions
    },
  },
  mounted () {
    this.isLoading = false
  }
}
